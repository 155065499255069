import {NgModule} from '@angular/core';
import {LayoutRoutingModule} from './layout-routing.module';
import {LayoutComponent} from './layout.component';
import {SettingsComponent} from './components/settings/settings.component';
import {CdaCommonModule} from '../shared/cdacommon.module';

@NgModule({
  declarations: [LayoutComponent, SettingsComponent],
  imports: [
    LayoutRoutingModule,
    CdaCommonModule
  ]
})
export class LayoutModule {
}
