import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, NavigationStart, Router} from '@angular/router';
import {AuthentificationModel} from '../shared/model/authentification.model';
import {Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {AuthService} from '../shared/service/authService.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  _authenficiationModel: AuthentificationModel;
  _roles: string[];
  _adminAcessBoolean: Boolean = Boolean(false);

  constructor(private _translate: TranslateService, private _router: Router, private _authService: AuthService) {
    _translate.setDefaultLang('fr');
    this._translate.currentLang = 'fr';
  }

  ngOnInit() {
    this._authenficiationModel = window.history.state;
    this._roles = this._authService.getRoles();
    this.checkRight();
  }

  checkRight() {
    const rolesOfLoggedUser: string[] = this._authService.getRoles();
    if (rolesOfLoggedUser.indexOf('Super-Administrateur') > -1 || rolesOfLoggedUser.indexOf('Administrateur') > -1) {
      this._adminAcessBoolean = Boolean(true);
    }
  }
}
