import {Component, OnInit} from '@angular/core';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDateFormats, MatSnackBar, NativeDateAdapter} from '@angular/material';
import {formatDate} from '@angular/common';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../../shared/service/api.service';
import {Router} from '@angular/router';
import {ChantierPourTacheModel} from '../../../shared/model/chantierPourTache.model';
import {SaveTacheModel} from '../../../shared/model/saveTache.model';
import {ActiviteModel} from '../../../shared/model/activite.model';
import {TypeMediaModel} from '../../../shared/model/TypeMedia.model';
import {NgxImageCompressService} from 'ngx-image-compress';

export const MY_FORMAT: MatDateFormats = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export class PickDateAdapter extends NativeDateAdapter {
  // A laisser en commentaire sinon, ça plante! (StaticInjectorError !??) => JPL
  // format(date: Date, displayFormat: Object): string {
  //   if (displayFormat === 'input') {
  //     return formatDate(date, 'dd-MM-yyyy', this.locale);
  //   } else {
  //     return date.toDateString();
  //   }
  // }
}

@Component({
  selector: 'app-tache-add',
  templateUrl: './tache-add.component.html',
  styleUrls: ['./tache-add.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: PickDateAdapter},
    {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMAT},
  ]
})

export class TacheAddComponent implements OnInit {

  // pour la compression d'une image
  fileZ: any;
  localUrlZ: any;
  localCompressedUrlZ: any;
  sizeOfOriginalImage: number;
  sizeOfCompressedImage: number;
  imgResultBeforeCompress: string;
  imgResultAfterCompress: string;
  // fin

  _ajoutTacheForm: FormGroup;

  _selectedChantier: ChantierPourTacheModel;
  _chantier = new FormControl(this._selectedChantier);
  chantiers: ChantierPourTacheModel[];
  _selectedTypemedia: TypeMediaModel;
  _typeMedia = new FormControl(this._selectedTypemedia);

  _selectedActivite: ActiviteModel;
  _activite = new FormControl(this._selectedActivite);
  activites;
  typeMedias;
  BooleanVideo = true;

  userFile;
  public imagePath;
  public videoPath;
  public message: String;
  imgUrl: any;
  videoUrl: any;
  isImage = false;
  isVideo = false;

  _nomFichier = new FormControl(this.imgUrl);

  _date: Date = new Date();

  _saveTacheModel: SaveTacheModel;
  _isCreateOk = false;
  public lienIconeCamera: String = '../assets/images/camera_icon.jpg';

  initForm() {
    this._ajoutTacheForm = this._fb.group({
      _chantier: this._chantier,
      _activite: this._activite,
      _titre: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z0-9 ]*$')]),
      _typeMedia: this._typeMedia,
      _nomFichier: new FormControl('', Validators.required),
      _description: new FormControl(''),
      _date: new FormControl(this._date)
    });
  }

  constructor(private _fb: FormBuilder, private _router: Router, private _apiService: ApiService, private snackBar: MatSnackBar, private dateAdapter: DateAdapter<Date>,
              private imageCompress: NgxImageCompressService) {
    this.initForm();
    this.dateAdapter.setLocale('fr');
  }

  /**
   * Compression d'une image
   */
  selectFile(event: any) {
    let fileName: any;
    this.fileZ = event.target.files[0];
    fileName = this.fileZ['name'];
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        this.localUrlZ = event.target.result;
        this.compressFile(this.localUrlZ, fileName);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  compressFile(image, fileName) {
    const orientation = -1;
      this.sizeOfOriginalImage = this.imageCompress.byteCount(image) / (1024 * 1024);
      console.warn('Size in bytes is now:', this.sizeOfOriginalImage);
    if (this.sizeOfOriginalImage > 2) {    // Si l'image fait + de 2Mo (environ) alors on baisse la qualité de 50%
      this.imageCompress.compressFile(image, orientation, 100, 50).then(result => {
        this.imgResultAfterCompress = result;
        this.localCompressedUrlZ = result;
        this.sizeOfCompressedImage = this.imageCompress.byteCount(result) / (1024 * 1024);
        console.warn('Size in bytes after compression:', this.sizeOfCompressedImage);
        // create file from byte
        const imageName = fileName;
        // call method that creates a blob from dataUri
        const imageBlob = this.dataUritoBlob(this.imgResultAfterCompress.split(',')[1]);
        // imageFile created below is the new compressed file which can be send to API in form data
        const imageFile = new File([result], imageName, {type: 'image/jpeg'});
        this.userFile = imageBlob;
        console.log('nom du fichier:', this.userFile);
      });
    }
  }

  dataUritoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], {type: 'image/jpeg'});
    return blob;
  }

  // Fin compression image
  ngOnInit() {
    this.fetchAllChantier();
    this.fetchAllActivite();
    this.fetchMedia();
  }

  /**
   * Récupérer la liste des chantiers
   */
  fetchAllChantier() {
    this._apiService.fetchAllChantier().subscribe(response =>
      this.chantiers = response);
  }

  /**
   * Récupérer la liste des activités
   */
  fetchAllActivite() {
    this._apiService.fetchAllActivites().subscribe(response =>
      this.activites = response);
  }

  /**
   * Methode pour récupérer la liste des types de Média (photo ou vidéo)
   */
  fetchMedia() {
    this._apiService.fetchMedia().subscribe(response =>
      this.typeMedias = response);
  }

  /**
   * Methode pour afficher une vignette de l'image sélectionnée
   */
  onSelectedFile(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.userFile = file;
      const mimeType = event.target.files[0].type;
      if (mimeType.match('video.*|image.*') == null) {
        this.message = 'Seules les images et les vidéos sont supportées';
        return;
      }
      if (mimeType.match('image.*')) {
        this.isImage = true;
        const reader = new FileReader();
        this.imagePath = file;
        reader.readAsDataURL(file);
        reader.onload = (_event) => {
          this.imgUrl = reader.result;
        };
      } else if (mimeType.match('video.*')) {
        this.isVideo = true;
        this.imgUrl = this.lienIconeCamera;
      }
    }
  }

  valider(_ajoutTacheForm: FormGroup, isValid: boolean) {
    if (isValid) {
      this._saveTacheModel = _ajoutTacheForm.value;
      this._apiService.saveTache(this._saveTacheModel, this.userFile).subscribe(response => {
        this._isCreateOk = response;
        if (this._isCreateOk) {
          this.snackBar.open('Création réussie', 'Fermer', {duration: 2000, verticalPosition: 'top'});
          this._router.navigate(['home']);
        } else {
          this.snackBar.open('Tâche déjà existante !', 'Fermer', {duration: 2000, verticalPosition: 'top'});
        }
      });
    } else {
      this.snackBar.open('Erreur', 'Fermer', {duration: 2000, verticalPosition: 'top'});
    }
  }

  gestionChoixMedia(event) {
    if (event.target._libelle === 'Video') {
      this.BooleanVideo = false;
    }
  }
}
