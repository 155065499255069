import {ActiviteModel} from './activite.model';

export class TacheModel {
  _id: number;
  _nomFichier: string;
  _titre: string;
  _activite: ActiviteModel;

  constructor(id: number, nomFichier: string, titre: string, activite: ActiviteModel) {
    this._id = id;
    this._nomFichier = nomFichier;
    this._titre = titre;
    this._activite = activite;
  }
}
