import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../../shared/service/authService.service';
import {UserDisplayModel} from '../../../shared/model/userDisplay.model';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {UserRolesDisplayModel} from '../../../shared/model/userRolesDisplay.model';
import {el} from '@angular/platform-browser/testing/src/browser_util';
import {StagiareModel} from '../../../shared/model/Stagiare.model';
import {ApiService} from '../../../shared/service/api.service';
import {MatSnackBar} from '@angular/material';
import {UserRolesModificationModel} from '../../../shared/model/userRolesModification.model';
import {parseHttpResponse} from 'selenium-webdriver/http';

@Component({
  selector: 'app-manage-authorization',
  templateUrl: './manage-authorization.component.html',
  styleUrls: ['./manage-authorization.component.scss']
})
export class ManageAuthorizationComponent implements OnInit {
  _userInfo: UserDisplayModel;
  _userRolesInfo: UserRolesDisplayModel[];
  roles: UserRolesDisplayModel [];
  displaycodeAfpa: boolean;
  _ajoutUserForm: FormGroup;
  _isSavedOk = false;
  _check: string;
  _stagiaire: StagiareModel;
  _idUser: string;
  _newRole: UserRolesModificationModel;
  _superAdminAccessBoolean = Boolean(false);
  _adminAccessBoolean = Boolean(false);

  initForm() {
    this._ajoutUserForm = this._fb.group({
      _code: new FormControl(''),
    });
  }

  constructor(private _activatedroute: ActivatedRoute, private _authService: AuthService, private _router: Router,
              private _fb: FormBuilder, private _apiService: ApiService, private snackBar: MatSnackBar) {
    this.initForm();
  }

  ngOnInit() {
    this.checkRight();
    this._activatedroute.paramMap.subscribe(params => {
      const _idKeycloakUser: string = params.get('id');
      this._idUser = _idKeycloakUser;
      this._authService.getUser(_idKeycloakUser).subscribe(userData => {
        this._userInfo = userData;
        this._authService.getUserRole(_idKeycloakUser).subscribe(roleList => {
          this._userRolesInfo = roleList;
          this._userInfo._statut = roleList[roleList.length - 1]._name;
          this._check = roleList[roleList.length - 1]._id;
          this.AfficherCodeAfpa(this._userInfo._statut);
          this.fetchStagiare(_idKeycloakUser);
        });
      });
    });
    this.fetchAllRolesOfRealm();
  }

  /**
   * methode qui permet de confirmer le role de l'utilisateur connecté
   */
  checkAccess(role: string) {
    this._userRolesInfo.forEach(userRoleInfo => {
      if (userRoleInfo._name === role) {
        return Boolean(true);
      }
    });
    return Boolean(false);
  }

  /**
   * methode qui permet de recuperer tous les roles du realm
   */
  fetchAllRolesOfRealm() {
    this._authService.getallRolesofRealm().subscribe(response => {
      const _allRoles = response;
      this.triRole(_allRoles);
    });
  }

  /**
   * methode qui permet de recuperer les données d'un stagiaire (code afpa)
   */
  fetchStagiare(id: string) {
    this._apiService.fetchStagiaire(id).subscribe(response => {
      const stagiaire = response;
      this._stagiaire = stagiaire;
    });
  }

  /**
   * methode qui permet d'afficher uniquement les roles possibles qui peuvent être affecté par l'utilisateur
   */
  triRole(tableau: UserRolesDisplayModel []) {
    const tab: UserRolesDisplayModel [] = [];
    tableau.forEach(role => {
      if (this._adminAccessBoolean) {
        if (role._name === 'Stagiaire') {
          tab.push(role);
        }
      }
      if (this._superAdminAccessBoolean) {
        if (role._name === 'Administrateur') {
          tab.push(role);
        }
      }
    });
    this.roles = tab;
  }

  /**
   * methode qui permet de modifier la valeur du bouleen permettant l'affichage du champs code afpa si l'utilsateur est stagiaire
   */
  AfficherCodeAfpa(role: string) {
    if (role === 'Stagiaire') {
      this.displaycodeAfpa = true;
    } else {
      this.displaycodeAfpa = false;
    }
  }

  /**
   * methode qui permet de recuperer le modele role a partir se son id
   */
  getRoleFromId(idRole: string) {
    this.roles.forEach((role => {
      if (role._id === idRole) {
        this._userRolesInfo.push(role);
        this._newRole = new UserRolesModificationModel(role._id, role._name);
      }
    }));
  }

  /**
   * methode qui permet de mettre a jour le nom du role en fonction de la minip de l'utilisateur
   */
  changement(value: string) {
    this._check = value;
  }

  /**
   * methode qui permet de verifier le role de l'utilisateur connecté et maj des boulean
   */
  checkRight() {
    const rolesOfLoggedUser: string[] = this._authService.getRoles();
    if (rolesOfLoggedUser.indexOf('Super-Administrateur') > -1) {
      this._superAdminAccessBoolean = Boolean(true);
    }
    if (rolesOfLoggedUser.indexOf('Administrateur') > -1) {
      this._adminAccessBoolean = Boolean(true);
    }
  }

  /**
   * validation du formulaire
   */
  valider(_ajoutUserForm: FormGroup, isValid: boolean) {
    if (isValid) {
      // if (this._check === 'User')
      this.getRoleFromId(this._check);

      // appel du service pour ajouter un role à un utilisateur dans keycloak
      this._authService.updateRole(this._idUser, new Array(this._newRole)).subscribe(responseA => {

        // verification du role affecté sur keycloak en appelant la liste des roles affectés de l'utilisateur concerné
        let booleanCheckNewRole: boolean = Boolean(false);
        this._authService.getUserRole(this._idUser).subscribe(roleList => {
            // console.log(roleList);
            roleList.forEach((role) => {
                if (this._newRole.name === role._name) {
                  booleanCheckNewRole = Boolean(true);
                }
              }
            );

            if (booleanCheckNewRole) {
              // cas du role stagiaire - sauvegarde du code Afpa dans la base de données
              if (this._newRole.name === 'Stagiaire') {
                const stagiaire = new StagiareModel(this._idUser, _ajoutUserForm.value._code);
                this._apiService.saveUser(stagiaire).subscribe(responseB => {
                  this._isSavedOk = responseB;
                  if (this._isSavedOk) { // confirmation de sauvegarde du code afpa dans la bdd
                    this.snackBar.open('Sauvegarde effectuée ', 'Fermer', {duration: 2000, verticalPosition: 'top'});
                    this._router.navigate(['/home/utilisateur/list']);
                  } else {
                    this.snackBar.open('Erreur 1', 'Fermer', {duration: 2000, verticalPosition: 'top'});
                  }
                });
              } else {
                this.snackBar.open('Sauvegarde effectuée ', 'Fermer', {duration: 2000, verticalPosition: 'top'});
                this._router.navigate(['/home/utilisateur/list']);
              }
            } else {
              this.snackBar.open('Erreur 2', 'Fermer', {duration: 2000, verticalPosition: 'top'});
            }
          }
        );
      });
    } else {
      this.snackBar.open('Erreur 3', 'Fermer', {duration: 2000, verticalPosition: 'top'});
    }

  }
}
