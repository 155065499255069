import {Component, OnInit} from '@angular/core';
import {TacheDisplayModel} from '../../../shared/model/tacheDisplay.model';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '../../../shared/service/api.service';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDateFormats, MatDialog, MatSnackBar, NativeDateAdapter} from '@angular/material';
import {AuthService} from '../../../shared/service/authService.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ChantierPourTacheModel} from '../../../shared/model/chantierPourTache.model';
import {TypeMediaModel} from '../../../shared/model/TypeMedia.model';
import {ActiviteModel} from '../../../shared/model/activite.model';
import {SaveTacheModel} from '../../../shared/model/saveTache.model';
import {NgxImageCompressService} from 'ngx-image-compress';

export const MY_FORMAT: MatDateFormats = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export class PickDateAdapter extends NativeDateAdapter {
  // A laisser en commentaire sinon, ça plante! (StaticInjectorError !??) => JPL
  // format(date: Date, displayFormat: Object): string {
  //   if (displayFormat === 'input') {
  //     return formatDate(date, 'dd-MM-yyyy', this.locale);
  //   } else {
  //     return date.toDateString();
  //   }
  // }
}

@Component({
  selector: 'app-tache-modif',
  templateUrl: './tache-modif.component.html',
  styleUrls: ['./tache-modif.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: PickDateAdapter},
    {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMAT},
  ]
})
export class TacheModifComponent implements OnInit {
  _idTache: number;
  _tacheId: string;
  _tache: TacheDisplayModel;
  _updateTacheForm: FormGroup;
  chantiers: ChantierPourTacheModel[];
  activites: ActiviteModel[];
  typeMedias: TypeMediaModel[];
  BooleanVideo = true;

  updatedFile: any = null;
  selectedFile;
  public message: String;
  imgUrl: any = null;
  videoUrl: any;
  isImage = false;
  isVideo = false;
  _date: Date = new Date();

  _updateTacheModel: SaveTacheModel;
  _isCreateOk = false;
  public lienIconeCamera: String = '../assets/images/camera_icon.jpg';

  // pour la compression d'une image
  userFile;
  fileZ: any;
  localUrlZ: any;
  localCompressedUrlZ: any;
  sizeOfOriginalImage: number;
  sizeOfCompressedImage: number;
  imgResultBeforeCompress: string;
  imgResultAfterCompress: string;

  // fin
  /**
   * Compression d'une image
   */
  selectFile(event: any) {
    let fileName: any;
    this.fileZ = event.target.files[0];
    fileName = this.fileZ['name'];
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        this.localUrlZ = event.target.result;
        this.compressFile(this.localUrlZ, fileName);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  compressFile(image, fileName) {
    const orientation = -1;
    this.sizeOfOriginalImage = this.imageCompress.byteCount(image) / (1024 * 1024);
    console.warn('Size in bytes is now:', this.sizeOfOriginalImage);
    if (this.sizeOfOriginalImage > 2) {  // Si l'image fait + de 2Mo (environ) alors on baisse la qualité de 50%
      this.imageCompress.compressFile(image, orientation, 100, 50).then(result => {
        this.imgResultAfterCompress = result;
        this.localCompressedUrlZ = result;
        this.sizeOfCompressedImage = this.imageCompress.byteCount(result) / (1024 * 1024);
        console.warn('Size in bytes after compression:', this.sizeOfCompressedImage);
        // create file from byte
        const imageName = fileName;
        // call method that creates a blob from dataUri
        const imageBlob = this.dataUritoBlob(this.imgResultAfterCompress.split(',')[1]);
        // imageFile created below is the new compressed file which can be send to API in form data
        const imageFile = new File([result], imageName, {type: 'image/jpeg'});
        this.selectedFile = imageBlob;
        console.log('nom du fichier:', this.selectedFile);
      });
    }
  }

  dataUritoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], {type: 'image/jpeg'});
    return blob;
  }

  // Fin compression image

  /**
   * Méthode qui permet d'initialiser le formulaire lors de l'affichage
   */
  initForm() {
    this._updateTacheForm = this._fb.group({
      _selectedChantierControl: new FormControl('', Validators.required),
      _selectedActiviteControl: new FormControl('', Validators.required),
      _titre: new FormControl('', Validators.required),
      _selectedTypeMediaControl: new FormControl('', Validators.required),
      _nomFichier: new FormControl(''),
      _description: new FormControl(''),
      _date: new FormControl('', Validators.required)
    });
  }

  compareFn: ((f1: any, f2: any) => boolean) | null = this.compareByValue;

  compareByValue(f1: any, f2: any) {
    return f1 && f2 && f1._id === f2._id;
  }

  /**
   * Initilisation des valeurs dans le formulaire
   *
   * @param pTache
   */
  initValueForm(pTache: TacheDisplayModel) {
    this._updateTacheForm.get('_selectedChantierControl').setValue(pTache._chantier);
    this._updateTacheForm.get('_selectedActiviteControl').setValue(pTache._activite);
    this._updateTacheForm.get('_selectedTypeMediaControl').setValue(pTache._typeMedia);
    this._updateTacheForm.get('_titre').setValue(pTache._titre);
    // this._updateTacheForm.get('_nomFichier').setValue(pTache._nomFichier);
    this._updateTacheForm.get('_description').setValue(pTache._description);
    this._updateTacheForm.get('_date').setValue(new Date(pTache._date));
  }

  constructor(private _Activatedroute: ActivatedRoute, private _fb: FormBuilder, private _router: Router,
              private _apiService: ApiService, private snackBar: MatSnackBar, private dateAdapter: DateAdapter<Date>,
              private imageCompress: NgxImageCompressService) {
    this.dateAdapter.setLocale('fr');
    this.initForm();
  }

  ngOnInit() {
    this.fetchAllChantier();
    this.fetchAllActivite();
    this.fetchMedia();
    this.fetchTache();
    /*   this.initForm();*/
  }

  /**
   * Récupérer la liste des chantiers
   */
  fetchAllChantier() {
    this._apiService.fetchAllChantier().subscribe(response => {
      this.chantiers = response;
    });
  }

  /**
   * Récupérer la liste des activités
   */
  fetchAllActivite() {
    this._apiService.fetchAllActivites().subscribe(response => {
        this.activites = response;
      }
    );
  }

  /**
   * Methode pour récupérer la liste des types de Média (photo ou vidéo)
   */
  fetchMedia() {
    this._apiService.fetchMedia().subscribe(response => {
        this.typeMedias = response;
      }
    );
  }

  fetchTache() {
    this._Activatedroute.paramMap.subscribe(params => {
      // réception de l'id d'une Tache selectionné sur la page liste de Taches
      const _idTache: string = params.get('id');
      this._idTache = +_idTache;
      this._tacheId = _idTache;

      // recuperation de la tache par son id
      this._apiService.fetchTache(_idTache).subscribe(tache => {
        this._tache = tache;
        this.initValueForm(tache);
        if (tache._typeMedia._libelle === 'Video') {
          this.isVideo = true;
        }
        // this._selectedChantierControl = new FormControl(this._selectedChantier);

      });
    });
  }

  valider(_updateTacheForm: FormGroup, isValid: boolean) {

    const tache = new SaveTacheModel(this._tache._id,
      _updateTacheForm.value._nomFichier, _updateTacheForm.value._titre, _updateTacheForm.value._description,
      _updateTacheForm.value._date, _updateTacheForm.value._selectedChantierControl, _updateTacheForm.value._selectedActiviteControl,
      _updateTacheForm.value._selectedTypeMediaControl
    );
    if (this.imgUrl != null) {
      // dans le cas ou l'image a été changée / mise à jour
      this.updatedFile = this.selectedFile;
    } else {
      // dans le cas ou l'image n'a pas été changée
      this.updatedFile = new File([this._tache._byteTable], _updateTacheForm.value._nomFichier);
    }
    this._apiService.updateTache(tache, this.updatedFile).subscribe(response => {
      this._isCreateOk = response;
      if (this._isCreateOk) {
        this.snackBar.open('Mise a jour réussie', 'Fermer', {duration: 2000, verticalPosition: 'top'});
        this._router.navigate(['home']);
      } else {
        this.snackBar.open('Une erreur est arrivée', 'Fermer', {duration: 2000, verticalPosition: 'top'});
      }
    });
  }

  /**
   * Methode pour afficher une vignette de l'image sélectionnée
   */
  onSelectedFile(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.selectedFile = file;
      const mimeType = event.target.files[0].type;
      if (mimeType.match('video.*|image.*') == null) {
        this.message = 'Seules les images et les vidéos sont supportées';
        return;
      }
      if (mimeType.match('image.*')) {
        this.isImage = true;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (_event) => {
          this.imgUrl = reader.result;
        };
      } else if (mimeType.match('video.*')) {
        this.isVideo = true;
        this.imgUrl = this.lienIconeCamera;
      }
    }
  }

  gestionChoixMedia(event) {
    if (event.target._libelle === 'Video') {
      this.BooleanVideo = false;
    }
  }

}
