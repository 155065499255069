
export class UserRolesDisplayModel {

  _id?: string;
  _name?: string;
  _composite?: boolean;
  _clientRole?: boolean;
  _containerId?: string;

  constructor(id: string, name: string, composite: boolean, clientRole: boolean, containerId: string) {
    this._id = id;
    this._name = name;
    this._composite = composite;
    this._clientRole = clientRole;
    this._containerId = containerId;
  }
}
