import {UserRolesDisplayModel} from './userRolesDisplay.model';

export class UserDisplayModel {

  _id?: string;
  _firstName?: string;
  _lastName?: string;
  _username?: string;
  _email?: string;
  _statut?: string; // UserRolesDisplayModel[];
  _codeAfpa?: string;


  constructor(id: string, firstName: string, lastName: string, username: string, email: string, statut: string, codeAfpa: string) {
    this._id = id;
    this._firstName = firstName;
    this._lastName = lastName;
    this._username = username;
    this._email = email;
    this._statut = statut;
    this._codeAfpa = codeAfpa;
  }
}
