import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {MonprofilComponent} from './monprofil/monprofil.component';
import {UtilisateurListComponent} from '../utilisateur/components/utilisateur-list/utilisateur-list.component';
import {AuthGuardService} from '../shared/service/authGard.service';

const routes: Routes = [{
  path: '',
  component: MonprofilComponent,
  canActivate: [AuthGuardService],
  data: { roles: ['Super-Administrateur', 'Administrateur', 'Stagiaire', 'User'] }
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MonprofilRoutingModule { }
