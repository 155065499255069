import {Component, OnInit} from '@angular/core';
import {ActiviteModel} from '../../../shared/model/activite.model';
import {ApiService} from '../../../shared/service/api.service';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute} from '@angular/router';
import {ChantierModel} from '../../../shared/model/chantier.model';
import {TacheModel} from '../../../shared/model/tache.model';
import {ActiviteWithTacheListModel} from '../../../shared/model/activiteWithTacheList.model';
import {TacheOfActiviteModel} from '../../../shared/model/tacheOfActivite.model';
import {UserModel} from '../../../shared/model/user.model';
import {AuthService} from '../../../shared/service/authService.service';

@Component({
  selector: 'app-chantier-visu',
  templateUrl: './chantier-visu.component.html',
  styleUrls: ['./chantier-visu.component.scss']
})

export class ChantierVisuComponent implements OnInit {
  _nom: string;
  _localisation: string;
  _activiteList: ActiviteWithTacheListModel[] = [];

  constructor(private _Activatedroute: ActivatedRoute, private _apiService: ApiService, private _authService: AuthService, private _translate: TranslateService) {
    _translate.setDefaultLang('fr');
    this._translate.currentLang = 'fr';
  }

  ngOnInit() {
    // conversion des donnees issu des services vers une liste de modeles ActiviteWithTacheModel
    this.checkRight();
  }


  checkRight() {
    const rolesOfLoggedUser: string[] = this._authService.getRoles();
    const userModel: UserModel = this._authService.getLoggedUser();
    if (rolesOfLoggedUser.indexOf('Super-Administrateur') > -1 || rolesOfLoggedUser.indexOf('Administrateur') > -1) {
      this.getActivites('');
    } else {
      if (rolesOfLoggedUser.indexOf('Stagiaire') > -1) {
        this.getActivites(userModel.idTokenParsed.sub);
      }
    }
  }

  getActivites(idUser: string) {
    /**
     * réception de l'id du chantier selectionné sur la page liste de chantiers
     */
    this._Activatedroute.paramMap.subscribe(params => {
      const _idChantier: string = params.get('id');
      /**
       * recuperation de toutes les activités disponibles
       */
      this._apiService.fetchAllActivites().subscribe(activites => {
          const _activiteListInput: ActiviteModel[] = activites;
          const _activiteListResult: ActiviteWithTacheListModel[] = [];

          /**
           * recuperation d'un chantier contenant la liste de toutes les taches associées
           */
          this._apiService.fetchOneChantier(_idChantier, idUser).subscribe(chantier => {
              this._nom = chantier._nom;
              this._localisation = chantier._lieu._designation;

              /**
               * conversion vers une liste de model contenant uniquement les activités avec des taches
               */
              _activiteListInput.forEach((activite) => {
                const newTacheList: TacheOfActiviteModel[] = [];
                chantier._tacheList.forEach((tache) => {
                  if (tache._activite._id === activite._id) {
                    newTacheList.push(new TacheOfActiviteModel(tache._id, tache._nomFichier, tache._titre));
                  }});
                if (newTacheList.length > 0) {
                  _activiteListResult.push(new ActiviteWithTacheListModel(activite._id, activite._designation, newTacheList));
                }
              });
            }
          );
          this._activiteList = _activiteListResult;
        }
      );
    });

  }

}
