export class TacheOfActiviteModel {
  _id: number;
  _nomFichier: string;
  _titre: string;

  constructor(id: number, nomFichier: string, titre: string) {
    this._id = id;
    this._nomFichier = nomFichier;
    this._titre = titre;
  }
}
