import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {config} from '../../../config';
import {CommanditaireAjoutChantierModel} from '../model/commanditaireAjoutChantier.model';
import {SaveChantierModel} from '../model/saveChantier.model';
import {CommanditaireWithChantierBasicInfoModel} from '../model/commanditaireWithChantierBasicInfo.model';
import {ChantierBasicInfoModel} from '../model/chantierBasicInfo.model';
import {SaveCommanditaireModel} from '../model/saveCommanditaire.model';
import {SaveLocalisationModel} from '../model/saveLocalisation.model';
import {LieuAjoutChantierModel} from '../model/LieuAjoutChantier.model';
import {ActiviteModel} from '../model/activite.model';
import {TacheModel} from '../model/tache.model';
import {ChantierModel} from '../model/chantier.model';
import {SaveTacheModel} from '../model/saveTache.model';
import {ChantierPourTacheModel} from '../model/chantierPourTache.model';
import {TypeMediaModel} from '../model/TypeMedia.model';
import {TacheDisplayModel} from '../model/tacheDisplay.model';
import {CarouselModel} from '../model/Carousel.model';
import {StagiareModel} from '../model/Stagiare.model';


@Injectable({
  providedIn: 'root'
})
export class ApiService {


  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': `${config.originUrl}`,
      'Access-Control-Allow-Credentials': 'true',
    })
  };

  constructor(private http: HttpClient) {
  }

  // Handle API errors
  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }


  /**
   * Service de récupération des commanditaires avec la liste des chantiers respectifs
   */
  fetchCommanditaireWithChantier(idUser: string): Observable<any> {
    console.log('fetchCommanditaireWithChantier', idUser);
    console.log('fetchCommanditaireWithChantier', `${config.apiUrl}` + `${config.fetchAllChantierUri}` + `${config.operatorAddFirstParam}` + `${config.paramIdUser}` + `${idUser}`);
    return this.http.get<any>(`${config.apiUrl}` + `${config.fetchAllChantierUri}` + `${config.operatorAddFirstParam}` + `${config.paramIdUser}` + `${idUser}`, this.httpOptions)
      .pipe(
        map(commanditaireList =>
          commanditaireList.map((commanditaireJson) => new CommanditaireWithChantierBasicInfoModel(
            commanditaireJson._designation,
            commanditaireJson._chantierList.map((chantierJson) => new ChantierBasicInfoModel(
              chantierJson._id,
              chantierJson._nom,
              chantierJson._dateDebut,
              chantierJson._dateFin
            ))))),
        catchError(this.handleError)
        // map(response => console.log('1',response))
      );
  }

  /**
   * Service de récupération des commanditaires
   */
  fetchAllCommanditaire(): Observable<any> {
    return this.http.get<any>(`${config.apiUrl}` + `${config.fetchAllCommanditaireUri}`, this.httpOptions)
      .pipe(
        map(commanditaire =>
          commanditaire.map((commanditaireJson) => new CommanditaireAjoutChantierModel(
            commanditaireJson._id, commanditaireJson._designation
          ))),
        catchError(this.handleError)
      );
  }

  /**
   * Service de récupération des lieux
   */
  fetchAllLieu(): Observable<any> {
    return this.http.get<any>(`${config.apiUrl}` + `${config.fetchAllLocalisationUri}`, this.httpOptions)
      .pipe(
        map(lieu =>
          lieu.map((lieuJson) => new LieuAjoutChantierModel(
            lieuJson._id, lieuJson._designation
          ))),
        catchError(this.handleError)
      );
  }

  /**
   * Service de récupération de toutes les activités de references
   */
  fetchAllActivites(): Observable<any> {
    return this.http.get<any>(`${config.apiUrl}` + `${config.fetchAllActivitesUri}`, this.httpOptions)
      .pipe(
        map(activite =>
          activite.map((activiteJson) => new ActiviteModel(
            activiteJson._id, activiteJson._designation
          ))),
        catchError(this.handleError)
      );
  }


  // /**
  //  * Service de récupération d'un commanditaire par l'id d'un chantier
  //  */
  // fetchCommanditaireByChantierId(id: string): Observable<any> {
  //   return this.http.get<any>(`${config.apiUrl}` + `${config.fetchCommanditaireUri}` + `${id}`, this.httpOptions)
  //     .pipe(
  //       map(commanditaire =>
  //         new CommanditaireAjoutChantierModel(
  //           commanditaire._id,
  //           commanditaire._designation,
  //         )),
  //       // catchError(this.handleError)
  //       map(response => console.log(response))
  //     );
  // }

  /**
   * Service de récupération d'un chantier par son id
   */
  fetchChantier(id: string): Observable<any> {
    return this.http.get<any>(`${config.apiUrl}` + `${config.fetchChantierUri}` + `${config.operatorAddFirstParam}` +
      `${config.paramIdChantier}` + `${id}`, this.httpOptions)
      .pipe(
        map(chantier =>
            new SaveChantierModel(
              chantier._id,
              chantier._nom,
              new LieuAjoutChantierModel(
                chantier._lieu._id,
                chantier._lieu._designation
              ),
              new CommanditaireAjoutChantierModel(chantier._commanditaire._id, chantier._commanditaire._designation),
              chantier._dateDebut,
              chantier._dateFin),
          catchError(this.handleError)
          // map(response => console.log(response)
        ));
  }

  /**
   * Service de récupération d'un chantier par son id
   */
  fetchOneChantier(id: string, idUser: string): Observable<any> {
    console.log('get input', id, idUser);
    return this.http.get<any>(`${config.apiUrl}` + `${config.fetchOneChantierUri}` + `${config.operatorAddFirstParam}` +
      `${config.paramIdChantier}` + `${id}` + `${config.operatorAddOtherParam}` + `${config.paramIdUser}` + `${idUser}`, this.httpOptions)
      .pipe(
        map(chantier =>
          new ChantierModel(
            chantier._nom,
            new LieuAjoutChantierModel(
              chantier._lieu._id,
              chantier._lieu._designation
            ),
            chantier._tacheList.map((tacheJson) => new TacheModel(
              tacheJson._id,
              tacheJson._nomFichier,
              tacheJson._titre,
              new ActiviteModel(
                tacheJson._activite._id,
                tacheJson._activite._designation
              )
            )),
            chantier._dateDebut,
            chantier._dateFin
          )),
        catchError(this.handleError)
        // map(response => console.log(response)
      );
  }


  saveChantier(model: SaveChantierModel): Observable<any> {
    console.log('post input', JSON.stringify(model));
    return this.http.post<object>(`${config.apiUrl}` + `${config.saveChantierUri}`, JSON.stringify(model), this.httpOptions)
      .pipe(map(response => {
          console.log('post output', response);
          return response;
        }),
        catchError(this.handleError)
      );
  }

  saveCommanditaire(model: SaveCommanditaireModel): Observable<any> {
    console.log('post input', JSON.stringify(model));
    return this.http.post<object>(`${config.apiUrl}` + `${config.saveCommanditaireUri}`, JSON.stringify(model), this.httpOptions)
      .pipe(map(response => {
          console.log('post output', response);
          return response;
        }),
        catchError(this.handleError)
      );
  }

  /* fonction qui sert à envoyer l'information reçu du formulaire vers le back */
  saveLocalisation(model: SaveLocalisationModel): Observable<any> {
    console.log('post input', JSON.stringify(model));
    return this.http.post<object>(`${config.apiUrl}` + `${config.saveLocalisationUri}`, JSON.stringify(model), this.httpOptions)
      .pipe(map(response => {
          console.log('post output', response);
          return response;
        }),
        catchError(this.handleError)
      );
  }

  /**
   * Service de récupération des chantiers pour la création de tâches
   */
  fetchAllChantier(): Observable<any> {
    return this.http.get<any>(`${config.apiUrl}` + `${config.fetchAllChantierPourTacheUri}`, this.httpOptions)
      .pipe(
        map(chantier =>
          chantier.map((chantierJson) => new ChantierPourTacheModel(
            chantierJson._id, chantierJson._nom, chantierJson._commanditaire
          ))),
        catchError(this.handleError)
      );
  }

  /**
   * Service de récupération des types de Média pour la création de tâches
   */
  fetchMedia(): Observable<any> {
    return this.http.get<any>(`${config.apiUrl}` + `${config.fetchTypeMediaUri}`, this.httpOptions)
      .pipe(
        map(typeMedia =>
          typeMedia.map((typeJson) => new TypeMediaModel(
            typeJson._id, typeJson._libelle
          ))),
        catchError(this.handleError)
      );
  }


  /**
   * Service de récupération d'une tache par son id
   */
  fetchTache(id: string): Observable<any> {
    return this.http.get<any>(`${config.apiUrl}` + `${config.fetchTacheUri}` + `${config.operatorAddFirstParam}` +
      `${config.paramIdTache}` + `${id}`, this.httpOptions)
      .pipe(
        map((tache) =>
          new TacheDisplayModel(
            tache._id,
            tache._nomFichier,
            tache._titre,
            tache._description,
            tache._date,
            new ChantierPourTacheModel(
              tache._chantier._id,
              tache._chantier._nom,
              new CommanditaireAjoutChantierModel(
                tache._chantier._commanditaire._id,
                tache._chantier._commanditaire._designation)),
            new ActiviteModel(tache._activite._id, tache._activite._designation),
            new TypeMediaModel(tache._typeMedia._id, tache._typeMedia._libelle),
            tache._byteTable
          )),
        catchError(this.handleError)
        // map(response => console.log(response))
      );
  }

  /**
   * Service de récupération d'une photo par l'id de sa tâche
   */
  fetchPhoto(id: string): Observable<any> {
    return this.http.get<any>(`${config.apiUrl}` + `${config.fetchPhotoUri}` + `${id}`, this.httpOptions);
  }

  /**
   * Service de sauvegarde d'une tâche
   */
  saveTache(model: SaveTacheModel, selectedFile: any): Observable<any> {

    const uploadData = new FormData();
    uploadData.append('tache', JSON.stringify(model));
    uploadData.append('media', selectedFile);
    return this.http.post<any>(`${config.apiUrl}` + `${config.saveTacheUri}`, uploadData)
      .pipe(map(response => {
          console.log('post output', response);
          return response;
        }),

      );
  }

  /**
   * Update d'une tache
   */
  updateTache(model: SaveTacheModel, selectedFile: any): Observable<any> {
    const uploadData = new FormData();
    uploadData.append('tache', JSON.stringify(model));
    if (selectedFile !== null) {
      uploadData.append('media', selectedFile);
      console.log('post Input File:', selectedFile);
    }
    console.log('post Input', JSON.stringify(model));
    return this.http.post<any>(`${config.apiUrl}` + `${config.updateTacheUri}`, uploadData)
      .pipe(map(response => {
          console.log('post output', response);
          return response;
        }),
        catchError(this.handleError)
      );
  }
  /**
   * Service de suppression de tâche
   */
  deleteTache(id: string): Observable<any> {
    return this.http.get<any>(`${config.apiUrl}` + `${config.deleteTacheUri}` + `${config.operatorAddFirstParam}` +
      `${config.paramIdTache}` + `${id}`, this.httpOptions)
      .pipe(map(response => {
          return response;
        }),
        catchError(this.handleError)
      );
  }

  /**
   * Recuperation des images pour le Carousel
   */
  fetchAllimages() {
    return this.http.post<any>(`${config.apiUrl}` + `${config.fetchAllImagesUri}`, this.httpOptions)
      .pipe(
        map(carousel =>
          carousel.map((carouJson) => new CarouselModel(
            carouJson._nomFichier, carouJson._byteTable
          ))),
        catchError(this.handleError)
      );
  }
  /**
   * Service de suppression de chantier
   */
  deleteChantier(id: string): Observable<any> {
    return this.http.get<any>(`${config.apiUrl}` + `${config.deleteChantierUri}` + `${config.operatorAddFirstParam}` + `${config.paramIdChantier}` + `${id}`, this.httpOptions)
      .pipe(map(response => {
        console.log(response);
          return response;
        }),
        catchError(this.handleError)
      );
  }



  /**
   * Service de récupération de plusier taches par le code AFPA
   */
  SearchCodeAfpa(code: string): Observable<any> {
    return this.http.post<any>(`${config.apiUrl}` + `${config.searchByCodeUri}` + `${code}`, this.httpOptions)
      .pipe(
        map((tacheList) =>
          tacheList.map((tache) => new TacheDisplayModel(
            tache._id,
            tache._nomFichier,
            tache._titre,
            tache._description,
            tache._date,
            new ChantierPourTacheModel(
              tache._chantier._id,
              tache._chantier._nom,
              new CommanditaireAjoutChantierModel(
                tache._chantier._commanditaire._id,
                tache._chantier._commanditaire._designation)),
            new ActiviteModel(tache._activite._id, tache._activite._designation),
            new TypeMediaModel(tache._typeMedia._id, tache._typeMedia._libelle),
            tache._byteTable
          ))),
        catchError(this.handleError)
        // map(response => console.log(response))
      );
  }
  saveUser(model: StagiareModel): Observable<any> {
    return this.http.post<object>(`${config.apiUrl}` + `${config.saveUserUri}`, JSON.stringify(model), this.httpOptions)
      .pipe(map(response => {
        return response;
      }),  catchError(this.handleError)
      );
  }

  /**
   * Service de récupération d'un user par son id
   */
  fetchStagiaire(id: string): Observable<any> {
    return this.http.get<any>(`${config.apiUrl}` + `${config.fetchStagiaireUri}` + `${config.operatorAddFirstParam}` +
      `${config.paramIdUser}` + `${id}`, this.httpOptions)
      .pipe(
        map((stagiaire) =>
          new StagiareModel(
            stagiaire._idKeycloak,
            stagiaire._codeAfpa
          )),
        catchError(this.handleError)
        // map(response => console.log(response))
      );
  }
}

