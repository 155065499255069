export class ChantierBasicInfoModel {
  _id: string;
  _nom: string;
  _dateDebut: string;
  _dateFin: string;

  constructor(id: string, nom: string, dateDebut: string, dateFin: string) {
    this._id = id;
    this._nom = nom;
    this._dateDebut = dateDebut;
    this._dateFin = dateFin;
  }
}
