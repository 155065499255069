import {Component, OnInit} from '@angular/core';
import {ApiService} from '../shared/service/api.service';
import {CarouselModel} from '../shared/model/Carousel.model';
import {TacheDisplayModel} from '../shared/model/tacheDisplay.model';
import {ActiviteModel} from '../shared/model/activite.model';
import {ChantierDataModel} from '../shared/model/chantierData.model';
import {ChantierPourTacheModel} from '../shared/model/chantierPourTache.model';
import {ActiviteDataModel} from '../shared/model/activiteData.model';
import {TacheDataModel} from '../shared/model/tacheData.model';
import {Router} from '@angular/router';
import {TypeMediaModel} from '../shared/model/TypeMedia.model';


@Component({
  selector: 'app-authentification',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  value: string;
  datas: CarouselModel [];
  _tacheListInput: TacheDisplayModel [];
  _activiteListInput: ActiviteModel[];
  _chantierListInput: ChantierPourTacheModel[];
  _chantierDataList: ChantierDataModel[] ;
  datasPasVide: boolean;

  constructor(private _apiService: ApiService, private _router: Router) {
  }

  ngOnInit() {
    this.fetchAllimages();
    this.fetchAllActivites();
    this.fetchAllChantier();
  }

  /**
   * Récupérer la liste des images
   */
  fetchAllimages() {
    this._apiService.fetchAllimages().subscribe(response => {
      this.datas = response; });
    if (this.datas != null) {
     this.datasPasVide = true;
    }
  }

  /**
   * recherche via le code AFPA
   **/
  SearchCodeAfpa() {
    // console.log('-------------------------------------------------');
    this._apiService.SearchCodeAfpa(this.value).subscribe(reponse => {
      this._tacheListInput = reponse;
      const chantierTmp: ChantierDataModel[] = [];

      this._chantierListInput.forEach((chantierInput) => {
        const activiteTmp: ActiviteDataModel[] = [];

        this._activiteListInput.forEach((activiteInput) => {
          const tacheTmp: TacheDataModel[] = [];

          reponse.forEach((tacheFound) => {
            if (tacheFound._chantier._id === chantierInput._id && tacheFound._activite._id === activiteInput._id) {
              tacheTmp.push(new TacheDataModel(tacheFound._id, tacheFound._nomFichier, tacheFound._titre, tacheFound._description, tacheFound._typeMedia, tacheFound._byteTable));
            }
          });
          if (tacheTmp.length > 0) {
            activiteTmp.push(new ActiviteDataModel(activiteInput._id, activiteInput._designation, tacheTmp));
          }
        });
        if (activiteTmp.length > 0) {
          chantierTmp.push(new ChantierDataModel(+chantierInput._id, chantierInput._nom, activiteTmp));
        }
      });
      if (chantierTmp.length > 0) {
        this._chantierDataList = chantierTmp;
        // console.log(this._chantierDataList);
      }
    });
  }
  isVideo(_typeMedia: TypeMediaModel) {
    if (_typeMedia._libelle === 'Video') {
      return Boolean (true);
    } else {
      return Boolean (false);
    }
  }

  fetchAllActivites() {
    this._apiService.fetchAllActivites().subscribe(response => {
      this._activiteListInput = response; });
  }

  fetchAllChantier() {
    this._apiService.fetchAllChantier().subscribe(response => {
      this._chantierListInput = response; });
  }
  refreshSearch() {
    this._chantierDataList = null;
  }
}
