import {KeycloakProfile, KeycloakTokenParsed} from 'keycloak-js';

export class UserModel {

  idTokenParsed?: KeycloakTokenParsed;
  profile?: KeycloakProfile;



  constructor(idTokenParsed: Keycloak.KeycloakTokenParsed, profile: Keycloak.KeycloakProfile) {
    this.idTokenParsed = idTokenParsed;
    this.profile = profile;
  }
}
