import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {SaveChantierModel} from '../../../shared/model/saveChantier.model';
import {ApiService} from '../../../shared/service/api.service';

@Component({
  selector: 'app-chantier-update',
  templateUrl: './chantier-update.component.html',
  styleUrls: ['./chantier-update.component.scss']
})
export class ChantierUpdateComponent implements OnInit {
  _idChantier: string;
 chantier: SaveChantierModel;
  constructor(private _Activatedroute: ActivatedRoute,  private _apiService: ApiService) { }

  ngOnInit() {
    this._Activatedroute.paramMap.subscribe(params => {
      this._idChantier = params.get('id');
      this.fetchChantier();
    });
  }
  fetchChantier() {
    this._apiService.fetchChantier(this._idChantier).subscribe(chantier => {
      this.chantier = chantier;
    });
  }

}
