import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {TacheAddComponent} from './components/tache-add/tache-add.component';
import {TacheModifComponent} from './components/tache-modif/tache-modif.component';
import {TacheVisuComponent} from './components/tache-visu/tache-visu.component';
import {UtilisateurListComponent} from '../utilisateur/components/utilisateur-list/utilisateur-list.component';
import {AuthGuardService} from '../shared/service/authGard.service';

const routes: Routes = [{
  path: 'tacheAdd',
  component: TacheAddComponent,
  canActivate: [AuthGuardService],
  data: { roles: ['Super-Administrateur', 'Administrateur'] }
},
  {
    path: ':id/visu',
    component: TacheVisuComponent,
    canActivate: [AuthGuardService],
    data: { roles: ['Super-Administrateur', 'Administrateur', 'Stagiaire', 'User'] }
  },
  {
    path: ':id/update',
    component: TacheModifComponent,
    canActivate: [AuthGuardService],
    data: { roles: ['Super-Administrateur', 'Administrateur'] }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TacheRoutingModule { }
