import {CommanditaireAjoutChantierModel} from './commanditaireAjoutChantier.model';

export class ChantierPourTacheModel {
  _id: string;
  _nom: string;
  _commanditaire: CommanditaireAjoutChantierModel;


  constructor(id: string, nom: string, commanditaire: CommanditaireAjoutChantierModel) {
    this._id = id;
    this._nom = nom;
    this._commanditaire = commanditaire;
  }
}
