import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LayoutComponent} from './layout.component';
import {ChantierModule} from '../chantier/chantier.module';
import {LocalisationModule} from '../localisation/localisation.module';
import {UtilisateurModule} from '../utilisateur/utilisateur.module';
import {MonprofilModule} from '../monprofil/monprofil.module';
import {CommanditaireModule} from '../commanditaire/commanditaire.module';
import {TacheModule} from '../tache/tache.module';
import {AuthGuardService} from '../shared/service/authGard.service';

const routes: Routes = [{
  path: '',
  component: LayoutComponent,
  children: [
    {
      path: '',
      redirectTo: 'chantier',
    },
    {
      path: 'utilisateur',
      loadChildren: () => UtilisateurModule,
      canActivate: [AuthGuardService],
      data: { roles: ['Super-Administrateur', 'Administrateur'] }
    },
    {
      path: 'chantier',
      loadChildren: () => ChantierModule,
      canActivate: [AuthGuardService],
      data: { roles: ['Super-Administrateur', 'Administrateur', 'Stagiaire', 'User'] }
    },
    {
      path: 'localisation',
      loadChildren: () => LocalisationModule,
      canActivate: [AuthGuardService],
      data: { roles: ['Super-Administrateur', 'Administrateur'] }
    },
    {
      path: 'monprofil',
      loadChildren: () => MonprofilModule,
      canActivate: [AuthGuardService],
      data: { roles: ['Super-Administrateur', 'Administrateur', 'Stagiaire', 'User'] }
    },
    {
      path: 'commanditaire',
      loadChildren: () => CommanditaireModule,
      canActivate: [AuthGuardService],
      data: { roles: ['Super-Administrateur', 'Administrateur'] }
    },
    {
      path: 'tache',
      loadChildren: () => TacheModule,
      canActivate: [AuthGuardService],
      data: { roles: ['Super-Administrateur', 'Administrateur', 'Stagiaire', 'User'] }
    }
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LayoutRoutingModule {
}
