export class UserRolesModificationModel {

  id?: string;
  name?: string;

  constructor(pId: string, pName: string) {
    this.id = pId;
    this.name = pName;
  }
}
