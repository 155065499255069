import {Component, Input, OnInit} from '@angular/core';
import {AuthentificationModel} from '../../../shared/model/authentification.model';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {AuthService} from '../../../shared/service/authService.service';
import {UserModel} from '../../../shared/model/user.model';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  // _authenficiationModel: AuthentificationModel;
  _userModel: UserModel;

  constructor(private _translate: TranslateService, private _router: Router, private _authService: AuthService) {
    _translate.setDefaultLang('fr');
    this._translate.currentLang = 'fr';
  }

  ngOnInit() {
    // this._authenficiationModel = window.history.state;
    this._userModel = this._authService.getLoggedUser();
  }

  logout() {
    this._authService.logout();
  }

}
