export class ActiviteModel {
  _id: number;
  _designation: string;

  constructor(id: number, designation: string) {
    this._id = id;
    this._designation = designation;
  }
}




