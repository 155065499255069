import {TacheDataModel} from './tacheData.model';

export class ActiviteDataModel {
  _id: number;
  _designation: string;
  _tacheList: TacheDataModel[];

  constructor(id: number, designation: string, _tacheList: TacheDataModel[]) {
    this._id = id;
    this._designation = designation;
    this._tacheList = _tacheList;
  }
}




