export class TypeMediaModel {
  _id: number;
  _libelle: string;


  constructor(id: number, libelle: string) {
    this._id = id;
    this._libelle = libelle;
  }
}
