import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ApiService} from '../../shared/service/api.service';
import {AuthService} from '../../shared/service/authService.service';
import {UserModel} from '../../shared/model/user.model';

@Component({
  selector: 'app-monprofil',
  templateUrl: './monprofil.component.html',
  styleUrls: ['./monprofil.component.scss']
})
export class MonprofilComponent implements OnInit {
  _userData: UserModel = this._authService.getLoggedUser();
  _statut: string;

  constructor(private _router: Router, private _apiService: ApiService, private _authService: AuthService) {
  }

  ngOnInit() {
    // recherche du role a indiquer selon ce qui est affecté via keycloak + recherche du code Afpa dans l'api dans le cas d'un role stagiaire
    const statut = this.findGreatestRole(this._authService.getRoles());
    if (statut === 'Stagiaire') {
      this._apiService.fetchStagiaire(this._userData.idTokenParsed.sub).subscribe(response => {
          this._statut = statut + ' (code Afpa : ' + response._codeAfpa + ')';
        }
      );
    } else {
      this._statut = statut;
    }
  }

  /**
   * methode qui permet de recuperer le nom du role le plus élevé
   */
  findGreatestRole(userRoleNameList: string[]) {
    if (userRoleNameList.indexOf('Super-Administrateur') > -1) {
      return 'Super-Administrateur';
    }
    if (userRoleNameList.indexOf('Administrateur') > -1) {
      return 'Administrateur';
    }
    if (userRoleNameList.indexOf('Stagiaire') > -1) {
      return 'Stagiaire';
    }
    if (userRoleNameList.indexOf('User') > -1) {
      return 'User';
    }
  }
}
