import {ActiviteModel} from './activite.model';
import {TypeMediaModel} from './TypeMedia.model';
import {ChantierPourTacheModel} from './chantierPourTache.model';

export class SaveTacheModel {
  _id: number;
  _nomFichier: string;
  _titre: string;
  _description: string;
  _date: Date;
  _activite: ActiviteModel;
  _chantier: ChantierPourTacheModel;
  _typeMedia: TypeMediaModel;

   constructor(id: number, nomFichier: string, titre: string, description: string, date: Date, chantier: ChantierPourTacheModel,
               activite: ActiviteModel, type: TypeMediaModel) {
    this._id = id;
    this._nomFichier = nomFichier;
    this._titre = titre;
    this._description = description;
    this._date = date;
    this._chantier = chantier;
    this._activite = activite;
    this._typeMedia = type;
  }
}


