import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CommanditaireRoutingModule } from './commanditaire-routing.module';
import { CommanditaireComponent } from './commanditaire.component';
import { CommanditaireAddComponent } from './components/commanditaire-add/commanditaire-add.component';
import {CdaCommonModule} from '../shared/cdacommon.module';
import {ComponentsModule} from '../components/components.module';

@NgModule({
  declarations: [CommanditaireComponent, CommanditaireAddComponent],
  imports: [
    CdaCommonModule,
    CommanditaireRoutingModule,
    ComponentsModule
  ],
  exports: [
    CommanditaireAddComponent
  ]
})
export class CommanditaireModule { }
