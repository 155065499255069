import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-chantier-delete',
  templateUrl: './chantier-delete.component.html',
  styleUrls: ['./chantier-delete.component.scss']
})
export class ChantierDeleteComponent implements OnInit {
  _idChantier: number;

  constructor(private _Activatedroute: ActivatedRoute, ) { }

  ngOnInit() {
    this._Activatedroute.paramMap.subscribe(params => {
      this._idChantier = +params.get('id');
    });
  }

}
