import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TacheRoutingModule } from './tache-routing.module';
import { TacheVisuComponent } from './components/tache-visu/tache-visu.component';
import { TacheAddComponent } from './components/tache-add/tache-add.component';
import { TacheModifComponent } from './components/tache-modif/tache-modif.component';
import {CdaCommonModule} from '../shared/cdacommon.module';
import { TacheListComponent } from './components/tache-list/tache-list.component';
import {MatDatepickerModule, MatRadioModule} from '@angular/material';
import {ComponentsModule} from '../components/components.module';
import { TacheApercuComponent } from './components/tache-apercu/tache-apercu.component';

@NgModule({
  declarations: [TacheVisuComponent, TacheAddComponent, TacheModifComponent, TacheListComponent, TacheApercuComponent],
  imports: [
    CdaCommonModule,
    CommonModule,
    MatDatepickerModule,
    TacheRoutingModule,
    MatRadioModule,
    ComponentsModule
  ],
  exports: [
    TacheListComponent
  ]
})
export class TacheModule { }
