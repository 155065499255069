import {TypeMediaModel} from './TypeMedia.model';

export class TacheDataModel {
  _id: number;
  _nomFichier: string;
  _titre: string;
  _description: string;
  _typeMedia: TypeMediaModel;
  _byteTable: any;

  constructor(id: number, nomFichier: string, titre: string, description: string, typeMedia: TypeMediaModel, byteTable: any) {
    this._id = id;
    this._nomFichier = nomFichier;
    this._titre = titre;
    this._description = description;
    this._typeMedia = typeMedia;
    this._byteTable = byteTable;
  }
}
