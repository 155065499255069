import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LayoutModule} from './layout/layout.module';
import {HomeComponent} from './home/home.component';
import {AuthGuardService} from './shared/service/authGard.service';
import {CourrielComponent} from './courriel/courriel.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'mail',
    component: CourrielComponent,
  },
  {
    path: 'home',
    loadChildren: () => LayoutModule,
    canActivate: [AuthGuardService],
    canLoad:  [AuthGuardService],
    data: { roles: ['Super-Administrateur', 'Administrateur', 'Stagiaire', 'User'] }
  }];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
