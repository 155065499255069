import {Component, OnInit} from '@angular/core';
import {CommanditaireWithChantierBasicInfoModel} from '../../../shared/model/commanditaireWithChantierBasicInfo.model';
import {ApiService} from '../../../shared/service/api.service';
import {TranslateService} from '@ngx-translate/core';
import {DialogBoxComponent} from '../../../dialog-box/dialog-box.component';
import {MatDialog, MatSnackBar} from '@angular/material';
import {ActivatedRoute, Router} from '@angular/router';
import {ChantierModel} from '../../../shared/model/chantier.model';
import {AuthService} from '../../../shared/service/authService.service';
import {UserModel} from '../../../shared/model/user.model';

@Component({
  selector: 'app-chantier-list',
  templateUrl: './chantier-list.component.html',
  styleUrls: ['./chantier-list.component.scss']
})
export class ChantierListComponent implements OnInit {
  _commanditaireList: CommanditaireWithChantierBasicInfoModel[];
  _chantier: ChantierModel;
  _adminAcessBoolean: Boolean = Boolean(false);
  stagiaireBoolean: Boolean = Boolean(false);
  _booleanChantier: Boolean = Boolean(false);

  constructor(private _Activatedroute: ActivatedRoute, private apiService: ApiService, private _translate: TranslateService,
              private snackBar: MatSnackBar, private _router: Router, public dialog: MatDialog, private authService: AuthService) {
    _translate.setDefaultLang('fr');
    this._translate.currentLang = 'fr';
  }

  ngOnInit() {
    this.checkRight();
  }

  openDialog(id: string) {
    const dialogRef = this.dialog.open(DialogBoxComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result.event === 'Confirmer') {
        this.deleteChantier(id);
      }
    });
  }

  deleteChantier(id: string) {
    this.apiService.deleteChantier(id).subscribe(response => {
      const isDeletedOk: Boolean = Boolean(response);
      if (isDeletedOk) {
        this.snackBar.open('Suppression réussie', 'Fermer', {duration: 2000, verticalPosition: 'top'});
        this.reloadComponent();
      }
    });
  }

  checkRight() {
    const rolesOfLoggedUser: string[] = this.authService.getRoles();
    const userModel: UserModel = this.authService.getLoggedUser();
    if (rolesOfLoggedUser.indexOf('Super-Administrateur') > -1 || rolesOfLoggedUser.indexOf('Administrateur') > -1) {
      this._adminAcessBoolean = Boolean(true);
      /**
       * recuperation de la liste de tous les commnaditaires disponibles avec leur designation et leur liste de chantiers respective
       * chacun des chantiers est composé de son id, de son nom, de sa date de debut et date de fin
       */
      this.recuperationListeCommanditaireAvecChantier('');
    } else {
      if (rolesOfLoggedUser.indexOf('Stagiaire') > -1) {
        this.stagiaireBoolean = Boolean(true);
        this.recuperationListeCommanditaireAvecChantier(userModel.idTokenParsed.sub);
      }
    }
  }

  /**
   * recuperation de la liste des commanditaire avec la liste des chantiers associés en fonction du parametre d'entre
   * idUser = '' : pas de filtre
   * idUser = id de l'user connecté : filtre sur les tache qui comportent le code afpa associé dans la bdd (back api)
   */
  recuperationListeCommanditaireAvecChantier(idUser: string) {
    this.apiService.fetchCommanditaireWithChantier(idUser).subscribe(response => {
      this._commanditaireList = response;
      this.checkChantierList();
    });
  }

  /**
   * method pour verifier si il y a une list de chantier existante dans la list de commanditaire
   */
  checkChantierList() {
    this._commanditaireList.forEach(com => {
      if (com._chantierList.length > 0) {
        this._booleanChantier = Boolean(true);
      }
    });
    // console.log(booleanChantier);
    // return booleanChantier;
  }

  /**
   * reload de navigation pour mettre à jour les informations de la page suite à la  suppression de chantier
   */
  reloadComponent() {
    const currentUrl = this._router.url;
    this._router.routeReuseStrategy.shouldReuseRoute = () => false;
    this._router.onSameUrlNavigation = 'reload';
    this._router.navigate([currentUrl]);
  }
}
