import {ActiviteDataModel} from './activiteData.model';

export class ChantierDataModel {
  _id: number;
  _designation: string;
  _activiteList: ActiviteDataModel[];

  constructor(id: number, designation: string, activiteList: ActiviteDataModel[]) {
    this._id = id;
    this._designation = designation;
    this._activiteList = activiteList;
  }
}




