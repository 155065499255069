export const config = {
  apiUrl: 'https://byourtalent.com',
  originUrl: '*',
  logoutUrl: 'https://byourtalent.com',
  keycloakUrl: 'https://byourtalent.com',

  paramIdUser: 'idUser=',
  paramIdChantier: 'idChantier=',
  paramIdTache: 'idTache=',

  operatorAddOtherParam: '&',
  operatorAddFirstParam: '?',

  keycloakAdminAuthUri: '/auth/admin',
  keycloakrealmUri: '/realms/afpa-realm',
  authUri: '/api/app/login',

  // Commanditaire
  saveCommanditaireUri: '/api/app/commanditaire-save',
  fetchAllCommanditaireUri: '/api/app/commanditaire-list',

  // Localisation
  saveLocalisationUri: '/api/app/lieu-save',
  fetchAllLocalisationUri: '/api/app/localisation-list',

  // Chantier
  saveChantierUri: '/api/app/chantier-save',
  deleteChantierUri: '/api/app/chantier-delete',
  fetchAllChantierUri: '/api/app/chantier-list',
  fetchOneChantierUri: '/api/app/chantier-details',
  fetchChantierUri: '/api/app/chantier-get',


  // Tache
  saveTacheUri: '/api/app/tache-save',
  updateTacheUri: '/api/app/tache-update',
  deleteTacheUri: '/api/app/tache-delete',
  fetchAllTacheUri: 'api/app/tache-list',
  fetchTacheUri: '/api/app/tache-details',

  // Media
  fetchPhotoUri: '/api/app/photo?id=',
  fetchTypeMediaUri: '/api/app/allTypeMedia',

  // Stagiaire
  saveUserUri: '/api/app/stagiaire-save',
  fetchStagiaireUri: '/api/app/stagiaire-get',

  // Hors connection
  fetchAllImagesUri: '/api/appFreeAccess/allImages',
  searchByCodeUri: '/api/appFreeAccess/find-tache-for-code?code=',
  // Activite
  fetchAllActivitesUri: '/api/appFreeAccess/activite-list',
  // Chantier
  fetchAllChantierPourTacheUri: '/api/appFreeAccess/allChantier',

};
