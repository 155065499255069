import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '../../../shared/service/api.service';
import {TacheDisplayModel} from '../../../shared/model/tacheDisplay.model';
import {MatSnackBar} from '@angular/material';
import {MatDialog} from '@angular/material/dialog';
import {DialogBoxComponent} from '../../../dialog-box/dialog-box.component';
import {AuthService} from '../../../shared/service/authService.service';

@Component({
  selector: 'app-tache-visu',
  templateUrl: './tache-visu.component.html',
  styleUrls: ['./tache-visu.component.scss']
})
export class TacheVisuComponent implements OnInit {
  _tache: TacheDisplayModel;
  _idTache: number;
  _isDeletedOk = false;
  _adminAcessBoolean: Boolean = Boolean(false);
  isPhoto: boolean = Boolean(true);

  constructor(private _Activatedroute: ActivatedRoute, private _apiService: ApiService, private _router: Router,
              private snackBar: MatSnackBar, public dialog: MatDialog, private authService: AuthService) {
  }

  ngOnInit() {
    this.showTache();
    this.checkRight();
  }

  showTache() {
    this._Activatedroute.paramMap.subscribe(params => {
      // réception de l'id d'une Tache selectionné sur la page liste de Taches
      const _idTache: string = params.get('id');
      this._idTache = + _idTache;

      // recuperation de la tache par son id
      this._apiService.fetchTache(_idTache).subscribe(tache => {
        this._tache = tache;
        if (this._tache._typeMedia._libelle === 'Video') {
          this.isPhoto = false;
        }
      });
    });
  }

  // Fenetre Pop-Up confirmation suppression de la tâche
  openDialog() {
    const dialogRef = this.dialog.open(DialogBoxComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result.event === 'Confirmer') {
        this.deleteTache();
      }
    });
  }

  // Methode qui supprime une tâche
  deleteTache() {
    this._Activatedroute.paramMap.subscribe(params => {
      const _idTache: string = params.get('id');

      this._apiService.deleteTache(_idTache).subscribe(response => {
        this._isDeletedOk = response;
        if (this._isDeletedOk) {
          this.snackBar.open('Suppression réussie', 'Fermer', {duration: 2000, verticalPosition: 'top'});
          this._router.navigate(['home/chantier/' + this._tache._chantier._id + '/visu']);
        }});
    });
  }

  checkRight() {
    const rolesOfLoggedUser: string[] = this.authService.getRoles();
    if (rolesOfLoggedUser.indexOf('Super-Administrateur') > -1 || rolesOfLoggedUser.indexOf('Administrateur') > -1) {
      this._adminAcessBoolean = Boolean(true);
    }
  }
}
