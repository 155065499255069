import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {SaveCommanditaireModel} from '../../../shared/model/saveCommanditaire.model';
import {Router} from '@angular/router';
import {ApiService} from '../../../shared/service/api.service';
import {MatSnackBar} from '@angular/material';

@Component({
  selector: 'app-commanditaire-add',
  templateUrl: './commanditaire-add.component.html',
  styleUrls: ['./commanditaire-add.component.scss']
})
export class CommanditaireAddComponent implements OnInit {
  error = null;
  _ajoutComForm: FormGroup;
  _isCreateOk = false;
  _saveCommanditaireModel: SaveCommanditaireModel;

  initForm() {
    this._ajoutComForm = this._fb.group({
      _designation: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z0-9 ]*$')])
    });
  }

  constructor(private _fb: FormBuilder, private _router: Router, private _apiService: ApiService, private snackBar: MatSnackBar) {
    this.initForm();
  }

  ngOnInit() {
  }

//  evaluaTionTexte(msg: string) {
  //  if (/[.<>,:/?§!=})@`/|[{~]/.test(msg)) {
    //  this.error = 'saisie des caracters (/}]@\|[#?.!§:,) Interdite!';
    //    this._ajoutComForm.get('_designation').setValue(msg.replace(/[.<>,:/?§!=})@`/|[{~]/, ''));
      // this.error = 'Par mesure de précautions les caractères spéciaux sont supprimé';
    // }  else {
     // this.error = null;
    // }

 // }

  valider(_ajoutComForm: FormGroup, isValid: boolean) {
    if (isValid) {
      this._saveCommanditaireModel = _ajoutComForm.value;
      this._apiService.saveCommanditaire(this._saveCommanditaireModel).subscribe(response => {
        this._isCreateOk = response;
        if (this._isCreateOk) {
          this.snackBar.open('Création réussie', 'Fermer', {duration: 2000, verticalPosition: 'top'});
          this._router.navigate(['home']);
        } else {
          this.snackBar.open('Commanditaire déja existant !', 'Fermer', {duration: 2000, verticalPosition: 'top'});
        }
      });
    } else {
      this.snackBar.open('Erreur', 'Fermer', {duration: 2000, verticalPosition: 'top'});
    }
  }
}
