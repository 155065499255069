import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MonprofilRoutingModule } from './monprofil-routing.module';
import { MonprofilComponent } from './monprofil/monprofil.component';
import {CdaCommonModule} from '../shared/cdacommon.module';
import {ComponentsModule} from '../components/components.module';

@NgModule({
  declarations: [MonprofilComponent],
  imports: [
    CommonModule,
    MonprofilRoutingModule,
    CdaCommonModule,
    ComponentsModule
  ]
})
export class MonprofilModule { }
