import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {UtilisateurListComponent} from './components/utilisateur-list/utilisateur-list.component';
import {AuthGuardService} from '../shared/service/authGard.service';
import {ManageAuthorizationComponent} from './components/manage-authorization/manage-authorization.component';

const routes: Routes = [{
  path: 'list',
  component: UtilisateurListComponent,
  canActivate: [AuthGuardService],
  data: {roles: ['Super-Administrateur', 'Administrateur']}
},
  {
    path: 'list/manage/:id',
    component: ManageAuthorizationComponent,
    canActivate: [AuthGuardService],
    data: {roles: ['Super-Administrateur', 'Administrateur']}
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UtilisateurRoutingModule {
}
