import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort, MatTableDataSource} from '@angular/material';
import {AuthService} from '../../../shared/service/authService.service';
import {UserDisplayModel} from '../../../shared/model/userDisplay.model';
import {UserRolesDisplayModel} from '../../../shared/model/userRolesDisplay.model';
import {ApiService} from '../../../shared/service/api.service';


@Component({
  selector: 'app-utilisateur',
  templateUrl: './utilisateur-list.component.html',
  styleUrls: ['./utilisateur-list.component.scss']
})
export class UtilisateurListComponent implements OnInit   {
  displayedColumns: string[] = ['nom', 'prenom', 'adressemail', 'statut', 'codeafpa', 'profil'];
  dataSource: MatTableDataSource<UserDisplayModel>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;


  constructor(private _authService: AuthService, private _apiService: ApiService) {
  }

  ngOnInit() {
    /**
     * recuperation de tous les utilisateurs via keycloak et recuperation du code afpa pour les stagiaires
     */
    this._authService.getUsers().subscribe(userslist => {
      userslist.forEach((user) => {
        this._authService.getUserRole(user._id).subscribe(roleList => {
          const roleUserList: string[] = [];
          roleList.forEach((role) => {
            roleUserList.push(role._name);
          });

          user._statut = this.findGreatestRole(roleUserList);
          if (user._statut === 'Stagiaire') {
            this._apiService.fetchStagiaire(user._id).subscribe(response => {
              user._codeAfpa = response._codeAfpa;
              }
            );
          }
        });
      });

      // Assign the data to the data source for the table to render
      this.dataSource = new MatTableDataSource<UserDisplayModel>(userslist);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    } );
  }

  /**
   * methode qui permet de rechercher le role le plus pertinent a afficher
   */
  findGreatestRole(userRoleNameList: string[]) {
    if (userRoleNameList.indexOf('Super-Administrateur') > -1) {
      return 'Super-Administrateur';
    }
    if (userRoleNameList.indexOf('Administrateur') > -1) {
      return 'Administrateur';
    }
    if (userRoleNameList.indexOf('Stagiaire') > -1) {
      return 'Stagiaire';
    }
    if (userRoleNameList.indexOf('User') > -1) {
      return 'User';
    }
  }

  /**
   * methode qui permet de confirmer le role SuperAdmin d'un utilisateur - appelée dans le html afin d'éviter de pouvoir modifier son role
   */
  checkUserAsSuperAdmin(statut: string) {
    if (statut === 'Super-Administrateur') {
      return true;
    } else {
      return false;
    }
  }

  /* Champs "Recherche" */
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

}
