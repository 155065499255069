import { NgModule } from '@angular/core';
import { ComponentsRoutingModule } from './components-routing.module';
import { HomeButtonComponent } from './home-button/home-button.component';
import {CdaCommonModule} from '../shared/cdacommon.module';

@NgModule({
  declarations: [HomeButtonComponent],
  imports: [
    CdaCommonModule,
    ComponentsRoutingModule
  ],
  exports: [
    HomeButtonComponent
  ]
})
export class ComponentsModule { }
