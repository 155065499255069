import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {ChantierAddComponent} from './components/chantier-add/chantier-add.component';
import {ChantierListComponent} from './components/chantier-list/chantier-list.component';
import {ChantierVisuComponent} from './components/chantier-visu/chantier-visu.component';
import {ChantierUpdateComponent} from './components/chantier-update/chantier-update.component';
import {ChantierDeleteComponent} from './components/chantier-delete/chantier-delete.component';
import {UtilisateurListComponent} from '../utilisateur/components/utilisateur-list/utilisateur-list.component';
import {AuthGuardService} from '../shared/service/authGard.service';


const routes: Routes = [{
    path: '',
    component: ChantierListComponent
  },
  {
    path: 'add',
    component: ChantierAddComponent,
    canActivate: [AuthGuardService],
    data: { roles: ['Super-Administrateur', 'Administrateur'] }
  },
  {
    path: ':id/visu',
    component: ChantierVisuComponent
  },
  {
    path: ':id/update',
    component: ChantierUpdateComponent,
    canActivate: [AuthGuardService],
    data: { roles: ['Super-Administrateur', 'Administrateur'] }
  },
  {
    path: ':id/delete',
    component: ChantierDeleteComponent,
    canActivate: [AuthGuardService],
    data: { roles: ['Super-Administrateur', 'Administrateur'] }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ChantierRoutingModule { }
