import {Component, Input, OnInit} from '@angular/core';
import {TacheDisplayModel} from '../../../shared/model/tacheDisplay.model';
import {ActivatedRoute} from '@angular/router';
import {ApiService} from '../../../shared/service/api.service';

@Component({
  selector: 'app-tache-apercu',
  templateUrl: './tache-apercu.component.html',
  styleUrls: ['./tache-apercu.component.scss']
})
export class TacheApercuComponent implements OnInit {
  @Input()
  _tache: TacheDisplayModel;

  _image;

  isPhoto: boolean = Boolean(true);

  constructor(private _Activatedroute: ActivatedRoute, private _apiService: ApiService) {
  }

  ngOnInit() {
    this._apiService.fetchTache(this._tache._id.toString()).subscribe(response => {
        this._image = 'data:image/png;base64,' + response._byteTable;
        if (response._typeMedia._libelle === 'Video') {
          this.isPhoto = false;
        }
      }
    );

  }
}
