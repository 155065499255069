import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {SaveLocalisationModel} from '../../../shared/model/saveLocalisation.model';
import {Router} from '@angular/router';
import {ApiService} from '../../../shared/service/api.service';
import {MatSnackBar} from '@angular/material';

@Component({
  selector: 'app-localisation-add',
  templateUrl: './localisation-add.component.html',
  styleUrls: ['./localisation-add.component.scss']
})
export class LocalisationAddComponent implements OnInit {

  _ajoutLieuForm: FormGroup;
  _isCreateOk = false;
  _saveLocalisationModel: SaveLocalisationModel;

  constructor(private _fb: FormBuilder, private _router: Router, private _apiService: ApiService, private snackBar: MatSnackBar) {
    this.initForm();
  }

  initForm() {
    this._ajoutLieuForm = this._fb.group({
      _designation: new FormControl('', Validators.required)
    });
  }

  ngOnInit() {
  }

  valider(_ajoutLieuForm: FormGroup, isValid: boolean) {
    if (isValid) {
      /* on vient lire la valeur entrée dans le formulaire et on la met dans le model */
      this._saveLocalisationModel = _ajoutLieuForm.value;
      /* on envoie la valeur dans api.service.ts pour traitement */
      this._apiService.saveLocalisation(this._saveLocalisationModel).subscribe(response => {
        this._isCreateOk = response;
        /* on reçoit la réponse (boolean) pour vérif si ajout OK*/
        if (this._isCreateOk) {
          this.snackBar.open('Création réussie', 'Fermer', {duration: 2000, verticalPosition: 'top'});
          this._router.navigate(['home']);
        } else {
          this.snackBar.open('Lieu déjà existant !', 'Fermer', {duration: 2000, verticalPosition: 'top'});
        }
      });
    } else {
      this.snackBar.open('Erreur', 'Fermer', {duration: 2000, verticalPosition: 'top'});
    }

  }

}
