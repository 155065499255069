import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material';


@Component({
  selector: 'app-dialog-box',
  templateUrl: './dialog-box.component.html',
  styleUrls: ['./dialog-box.component.scss']
})

export class DialogBoxComponent {

  constructor(public dialogRef: MatDialogRef<DialogBoxComponent>) {
  }

  doAction() {
    this.dialogRef.close({event: 'Confirmer'});
  }

  closeDialog() {
    this.dialogRef.close({event: 'Annuler'});
  }

}
