export class CarouselModel {
  _nomFichier: string;
  _byteTable: any;


  constructor(nomFichier: string, bytTable: any) {
    this._nomFichier = nomFichier;
    this._byteTable = bytTable;
  }
}
