import {ChantierBasicInfoModel} from './chantierBasicInfo.model';

export class CommanditaireWithChantierBasicInfoModel {
  _designation: string;
  _chantierList: ChantierBasicInfoModel[];

  constructor(designation: string, chantierList: ChantierBasicInfoModel[]) {
    this._designation = designation;
    this._chantierList = chantierList;
  }
}
