import {Component, Input, OnInit} from '@angular/core';
import {ActiviteWithTacheListModel} from '../../../shared/model/activiteWithTacheList.model';

@Component({
  selector: 'app-tache-list',
  templateUrl: './tache-list.component.html',
  styleUrls: ['./tache-list.component.scss']
})
export class TacheListComponent implements OnInit {
  @Input()
  _activite: ActiviteWithTacheListModel;

  constructor() { }

  ngOnInit() {
  }

  recupTache(id: number) {

  }

}
