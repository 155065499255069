import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatPaginatorModule} from '@angular/material/paginator';

import {
  MatButtonModule,
  MatCardModule, MatCheckboxModule, MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule, MatPaginator,
  MatProgressSpinnerModule, MatSelectModule,
  MatSidenavModule,
  MatSnackBarModule, MatTableModule,
  MatToolbarModule,
} from '@angular/material';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {StarRatingModule} from 'angular-star-rating';
import {MatDatepickerModule} from '@angular/material';
import {KeycloakAngularModule} from 'keycloak-angular';
import {NgxImageCompressService} from 'ngx-image-compress';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatCardModule,
    HttpClientModule,
    MatSidenavModule,
    MatToolbarModule,
    MatButtonModule,
    MatGridListModule,
    MatListModule,
    MatMenuModule,
    MatGridListModule,
    MatSnackBarModule,
    MatTableModule,
    MatSelectModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatPaginatorModule,
    KeycloakAngularModule,
    TranslateModule.forChild ({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      }
    }),
  ], exports: [
    CommonModule,
    MatProgressSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatCardModule,
    HttpClientModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatMenuModule,
    TranslateModule,
    MatSnackBarModule,
    MatGridListModule,
    MatTableModule,
    MatSelectModule,
    MatCheckboxModule,
    StarRatingModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatPaginatorModule,
    KeycloakAngularModule
  ],
  providers: [NgxImageCompressService]
})
export class CdaCommonModule {
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
