import {TacheModel} from './tache.model';
import {LieuAjoutChantierModel} from './LieuAjoutChantier.model';

export class ChantierModel {
  _nom: string;
  _lieu: LieuAjoutChantierModel;
  _tacheList: TacheModel[];
  _dateDebut: string;
  _dateFin: string;

  constructor(nom: string, lieu: LieuAjoutChantierModel, tacheList: TacheModel[], dateDebut: string, dateFin: string) {
    this._nom = nom;
    this._lieu = lieu;
    this._tacheList = tacheList;
    this._dateDebut = dateDebut;
    this._dateFin = dateFin;
  }
}
