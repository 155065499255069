import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LocalisationAddComponent} from './components/localisation-add/localisation-add.component';
import {UtilisateurListComponent} from '../utilisateur/components/utilisateur-list/utilisateur-list.component';
import {AuthGuardService} from '../shared/service/authGard.service';

const routes: Routes = [{
  path: 'add',
  component: LocalisationAddComponent,
  canActivate: [AuthGuardService],
  data: { roles: ['Super-Administrateur', 'Administrateur'] }
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LocalisationRoutingModule { }
