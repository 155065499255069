export class StagiareModel {
  _idKeycloak: string;
  _codeAfpa: string;


  constructor(idkeycloak: string, codeAfpa: string) {
    this._idKeycloak = idkeycloak;
    this._codeAfpa = codeAfpa;
  }
}
