import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UtilisateurRoutingModule } from './utilisateur-routing.module';
import { UtilisateurListComponent } from './components/utilisateur-list/utilisateur-list.component';
import {CdaCommonModule} from '../shared/cdacommon.module';
import {ComponentsModule} from '../components/components.module';
import { ManageAuthorizationComponent } from './components/manage-authorization/manage-authorization.component';
import {MatRadioModule} from '@angular/material';

@NgModule({
  declarations: [UtilisateurListComponent, ManageAuthorizationComponent],
  imports: [
    CommonModule,
    UtilisateurRoutingModule,
    CdaCommonModule,
    ComponentsModule,
    MatRadioModule
  ]
})
export class UtilisateurModule { }
