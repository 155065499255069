import {Component, Input, OnInit} from '@angular/core';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDateFormats, MatSnackBar} from '@angular/material';
import {LieuAjoutChantierModel} from '../../../shared/model/LieuAjoutChantier.model';
import {CommanditaireAjoutChantierModel} from '../../../shared/model/commanditaireAjoutChantier.model';
import {SaveChantierModel} from '../../../shared/model/saveChantier.model';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {ApiService} from '../../../shared/service/api.service';

export const MY_FORMAT: MatDateFormats = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-chantier-add',
  templateUrl: './chantier-add.component.html',
  styleUrls: ['./chantier-add.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMAT }
  ]
})
export class ChantierAddComponent implements OnInit {
  @Input()
  _chantier: SaveChantierModel;
  lieus: LieuAjoutChantierModel[];
  commanditaires: CommanditaireAjoutChantierModel[];
  _saveChantierModel: SaveChantierModel;
  todayDate: Date = new Date();
  _ajoutChantierForm: FormGroup;
  _selectedCommanditaire: CommanditaireAjoutChantierModel;
  _commanditaire = new FormControl(this._selectedCommanditaire);
  _isCreateOk = false;
  _selectedLieu: LieuAjoutChantierModel;
  _lieu = new FormControl(this._selectedLieu);
  error = null;


  initForm() {
    this._ajoutChantierForm = this._fb.group({
      _id: new FormControl(''),
      _nom: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z0-9 ]*$')]),
      _lieu: new FormControl('', Validators.required),
      _commanditaire: new FormControl('', Validators.required),
      _dateDebut: new FormControl(this.todayDate),
      _dateFin: new FormControl(this.todayDate)
    });
  }
  compareFn: ((f1: any, f2: any) => boolean) | null = this.compareByValue;

  compareByValue(f1: any, f2: any) {
    return f1 && f2 && f1._id === f2._id;
  }

  constructor(private _fb: FormBuilder, private _router: Router, private _apiService: ApiService, private snackBar: MatSnackBar, private dateAdapter: DateAdapter<Date>) {
    this.initForm();
    this.dateAdapter.setLocale('fr');
  }

  ngOnInit() {
    this.fetchAllCommanditaire();
    // this.fetchAllLieu();
    // this.initialForm(this._chantier);
  }

  /**
   * Récupérer la liste des commanditaire
   */
  fetchAllCommanditaire() {
    this._apiService.fetchAllCommanditaire().subscribe(response => {
      this.commanditaires = response;
      this.fetchAllLieu();
    });
  }


  /**
   * Récupérer la liste des Localisations
   */
  fetchAllLieu() {
    this._apiService.fetchAllLieu().subscribe(response => {
      this.lieus = response;
      this.initialForm(this._chantier);
    });
  }

  valider(_ajoutChantierForm: FormGroup, isValid: boolean) {
    if (isValid) {
      this._saveChantierModel = _ajoutChantierForm.value;
      this._apiService.saveChantier(this._saveChantierModel).subscribe(response => {
        this._isCreateOk = response;
        if (this._isCreateOk) {
          this.snackBar.open('Sauvegarde éffectué', 'Fermer', {duration: 2000, verticalPosition: 'top'});
          this._router.navigate(['home']);
        } else {
          this.snackBar.open('Chantier existant!', 'Fermer', {duration: 2000, verticalPosition: 'top'});
        }
      });
    } else {
      this.snackBar.open('Erreur', 'Fermer', {duration: 2000, verticalPosition: 'top'});
    }

  }
  initialForm(pChantier: SaveChantierModel) {
    if (this._chantier != null) {
      this._ajoutChantierForm.get('_id').setValue(pChantier._id);
      this._ajoutChantierForm.get('_nom').setValue(pChantier._nom);
      this._ajoutChantierForm.get('_lieu').setValue(pChantier._lieu);
      this._ajoutChantierForm.get('_commanditaire').setValue(pChantier._commanditaire);
      this._ajoutChantierForm.get('_dateDebut').setValue(new Date(pChantier._dateDebut));
      this._ajoutChantierForm.get('_dateFin').setValue(new Date(pChantier._dateFin));
    }
  }

  evaluaTionTexte(msg: string) {
    if (/[.<>,:/?§!=})@`/|[{~]/.test(msg)) {
      //  this.error = 'saisie des caracters (/}]@\|[#?.!§:,) Interdite!';
      this._ajoutChantierForm.get('_nom').setValue(msg.replace(/[.<>,:/?§!=})@`/|[{~]/, ''));
      this.error = 'Par mesure de précautions les caractères spéciaux sont supprimé';
    }  else {
      this.error = null;
    }

  }
}
