import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppComponent} from './app.component';
import {HomeComponent} from './home/home.component';
import {CdaCommonModule, HttpLoaderFactory} from './shared/cdacommon.module';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {KeycloakService} from 'keycloak-angular';
import {DialogBoxComponent} from './dialog-box/dialog-box.component';
import {MatDialogModule} from '@angular/material';
import {CarouselComponent} from './carousel/carousel.component';
import { CourrielComponent } from './courriel/courriel.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    DialogBoxComponent,
    CarouselComponent,
    CourrielComponent
  ],
  entryComponents: [DialogBoxComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CdaCommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    MatDialogModule,
  ],
  providers: [KeycloakService],
  bootstrap: [AppComponent]
})
export class AppModule {
}
