import {CommanditaireAjoutChantierModel} from './commanditaireAjoutChantier.model';
import {LieuAjoutChantierModel} from './LieuAjoutChantier.model';

export class SaveChantierModel {
  _id: number;
  _nom: string;
  _lieu: LieuAjoutChantierModel;
  _commanditaire: CommanditaireAjoutChantierModel;
  _dateDebut: Date;
  _dateFin: Date;


  constructor(id: number, nom: string, lieu: LieuAjoutChantierModel, commanditaire: CommanditaireAjoutChantierModel, dateDebut: Date, dateFin: Date) {
    this._id = id;
    this._nom = nom;
    this._lieu = lieu;
    this._commanditaire = commanditaire;
    this._dateDebut = dateDebut;
    this._dateFin = dateFin;
  }
}
