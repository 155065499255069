import {Injectable} from '@angular/core';
import {KeycloakService} from 'keycloak-angular';
import {UserModel} from '../model/user.model';
import {config} from '../../../config';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {stringify} from 'querystring';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {CommanditaireWithChantierBasicInfoModel} from '../model/commanditaireWithChantierBasicInfo.model';
import {ChantierBasicInfoModel} from '../model/chantierBasicInfo.model';
import {UserDisplayModel} from '../model/userDisplay.model';
import {UserRolesDisplayModel} from '../model/userRolesDisplay.model';
import {UserRolesModificationModel} from '../model/userRolesModification.model';
import {AuthentificationModel} from '../model/authentification.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      // 'Access-Control-Allow-Origin': `${config.originUrl}`,
      // 'Access-Control-Allow-Credentials': 'true',
    })
  };

  constructor(protected keycloakService: KeycloakService, private http: HttpClient) {
  }

  getLoggedUser() {
    try {
      const userModel: UserModel = new UserModel(this.keycloakService.getKeycloakInstance().idTokenParsed, this.keycloakService.getKeycloakInstance().profile);
      console.log('UserDetails - info part 1', userModel.idTokenParsed);
      console.log('UserDetails - info part 2', userModel.profile);
      console.log('UserRoles - info part 3', this.keycloakService.getUserRoles());
      return userModel;
    } catch (e) {
      // console.log('getLoggedUser exception', e);
      return undefined;
    }
  }

  logout() {
    this.keycloakService.logout(`${config.logoutUrl}`);
  }

  getRoles(): string[] {
    return this.keycloakService.getUserRoles();
  }

  // service de recuperation des données de tous les utilisateurs via keycloak
  getUsers(): Observable<any> {
    return this.http.get<any>( `${config.keycloakUrl}` + `${config.keycloakAdminAuthUri}` + `${config.keycloakrealmUri}` + '/users').pipe(
    map(users =>
      users.map((usrJson) => new UserDisplayModel(
        usrJson.id,
        usrJson.firstName,
        usrJson.lastName,
        usrJson.username,
        usrJson.email, '' , ''
        )))
  );
  }


  // service de recuperation des roles d'un utilisateur a partir de son id keycloak
  getUserRole(id: string): Observable<any> {
    return this.http.get<any>(`${config.keycloakUrl}` + `${config.keycloakAdminAuthUri}` + `${config.keycloakrealmUri}` + '/users/' + id + '/role-mappings/realm').pipe(
      map(roles =>
        roles.map((roleJson) =>
          new UserRolesDisplayModel(
            roleJson.id,
            roleJson.name,
            roleJson.composite,
            roleJson.clientId,
            roleJson.containerId
        )))
    );
  }

  // service de recuperation des données d'un utilisateur via keycloak
  getUser(id: string): Observable<any> {
    return this.http.get<any>(`${config.keycloakUrl}` + `${config.keycloakAdminAuthUri}` + `${config.keycloakrealmUri}` + '/users/' + id).pipe(
      map(usrJson => new UserDisplayModel(
          usrJson.id,
          usrJson.firstName,
          usrJson.lastName,
          usrJson.username,
          usrJson.email, '' , ''
        ))
    );
  }

  getallRolesofRealm() {
    return this.http.get<any>(`${config.keycloakUrl}` + `${config.keycloakAdminAuthUri}` + `${config.keycloakrealmUri}` + '/roles').pipe(
    map( roles => roles.map((roleJson) =>
        new UserRolesDisplayModel(
          roleJson.id,
          roleJson.name,
          roleJson.composite,
          roleJson.clientRole,
          roleJson.containerId
        )
    ))
    );
  }

  // A finir !!
  updateProfile(id, qlqchose) {
    return this.http.put<any>('http://localhost:8081/auth/admin/realms/heroes/users' + id, stringify(qlqchose));
  }

  updateRole(idKeycloakUser: string, role: UserRolesModificationModel[]): Observable<any> {
    console.log(JSON.stringify(role));
    return this.http.post<any>(`${config.keycloakUrl}` + `${config.keycloakAdminAuthUri}` + `${config.keycloakrealmUri}` + '/users/' +
      idKeycloakUser + '/role-mappings/realm', JSON.stringify(role), this.httpOptions);
  }
}

//
// authentification(model: AuthentificationModel): Observable<any> {
//   console.log('post input', JSON.stringify(model));
// return this.http.post<object>(`${config.apiUrl}` + `${config.authUri}`, JSON.stringify(model), this.httpOptions)
//   .pipe(map(response => {
//       console.log('post output', response);
//       return response;
//     }),
//     catchError(this.handleError)
//   );
// }
