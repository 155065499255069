import {TacheOfActiviteModel} from './tacheOfActivite.model';

export class ActiviteWithTacheListModel {
  _id: number;
  _designation: string;
  _tacheList: TacheOfActiviteModel[];

  constructor(id: number, designation: string, _tacheList: TacheOfActiviteModel[]) {
    this._id = id;
    this._designation = designation;
    this._tacheList = _tacheList;
  }
}




