import { NgModule } from '@angular/core';
import { ChantierRoutingModule } from './chantier-routing.module';
import { ChantierAddComponent } from './components/chantier-add/chantier-add.component';
import {MatDatepickerModule, MatNativeDateModule} from '@angular/material';
import {CdaCommonModule} from '../shared/cdacommon.module';
import { ChantierListComponent } from './components/chantier-list/chantier-list.component';
import { ChantierVisuComponent } from './components/chantier-visu/chantier-visu.component';
import { ChantierUpdateComponent } from './components/chantier-update/chantier-update.component';
import { ChantierDeleteComponent } from './components/chantier-delete/chantier-delete.component';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {TacheModule} from '../tache/tache.module';
import {ComponentsModule} from '../components/components.module';

@NgModule({
  declarations: [ChantierAddComponent, ChantierListComponent, ChantierVisuComponent, ChantierUpdateComponent, ChantierDeleteComponent],
  imports: [
    CdaCommonModule,
    ChantierRoutingModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ScrollingModule,
    TacheModule,
    ComponentsModule
  ]
})
export class ChantierModule { }
